import React from "react";
import "../css/Products.css"; // Ensure you have this CSS file for styling
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { CiShoppingCart } from "react-icons/ci";
import data from '../data/product.json'
import { BasicBreadcrumbs } from "../components/Breadcrumbs";
function Products() {
    const products = data;

    return (
        <>
            <BasicBreadcrumbs props={"Products"} />
            <div className="products_container">
                <div className="products_heading">
                    <h1>Our <span className="span">Most Demand</span> Products</h1>
                    <p>
                        Check out our latest products and find something you need for your business.
                    </p>
                </div>
                <div className="products_grid">
                    {products.map((product, index) => (
                        <Link to={`/products/${product.name.replace(/\s+/g, '-').toLowerCase()}`} key={index} onClick={() => window.scrollTo(0, 0)}>

                            <div key={index} className="product_card">
                                <img src={product.image} alt={product.name} className="product-image" />
                                <div className="product-info">
                                    <h3>{product.name}</h3>
                                    <p>{product.shortDescription}</p>
                                    <div className="buttons">
                                        <Link to='/buy-now' className="buy-now-button flex content-center items-center">
                                            Buy Now <CiShoppingCart size={20} />
                                        </Link>
                                        <Link to={`/products/${product.name.replace(/\s+/g, '-').toLowerCase()}`} className="view-more" onClick={() => window.scrollTo(0, 0)}>
                                            View More <IoIosArrowRoundForward size={20} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Products;
