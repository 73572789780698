import React, { useState, useEffect } from 'react';
import './HeroSection.css'
const HeroSection = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const totalItems = 3; // Number of images

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex % totalItems) + 1);
        }, 5000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, []);

    const goToPrev = () => {
        setActiveIndex(prevIndex => (prevIndex - 1 + totalItems) % totalItems || totalItems);
    };

    const goToNext = () => {
        setActiveIndex(prevIndex => (prevIndex % totalItems) + 1);
    };

    return (
        <div id="controls-carousel" className="relative hero_section w-full h-screen" data-carousel="static">
            <div className="relative h-full overflow-hidden rounded-lg">
                {Array.from({ length: totalItems }).map((_, index) => (
                    <div
                        key={index}
                        className={`absolute block w-full h-full transition-opacity duration-700 ease-in-out ${index + 1 === activeIndex ? 'opacity-100' : 'opacity-0'}`}
                        data-carousel-item={index + 1 === activeIndex ? "active" : ""}
                    >
                        <img
                            src={`../images/hero-slider-${index + 1}.png`}
                            className="absolute block w-full h-full object-contain top-0 left-0"
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                ))}
            </div>

            <button
                type="button"
                className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-prev
                onClick={goToPrev}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-4 h-4 text-blue-600 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                    </svg>
                    <span className="sr-only">Previous</span>
                </span>
            </button>

            <button
                type="button"
                className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-next
                onClick={goToNext}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-4 h-4 text-blue-600 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                    </svg>
                    <span className="sr-only">Next</span>
                </span>
            </button>
        </div>
    );
};

export default HeroSection;
