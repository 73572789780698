import React from 'react';
import { FaShieldAlt, FaTruck, FaGlobe } from 'react-icons/fa';
import { FaStarOfLife } from "react-icons/fa";
import 'aos/dist/aos.css';
import { BasicBreadcrumbs } from '../components/Breadcrumbs';
const AboutUs = () => {
    return (

        <>
            <BasicBreadcrumbs props="About Us" />

            <div className=" p-6 about-us-page">
                <section className="m-2">
                    <div>
                        <h1 className="heading_1">About Us</h1>
                        <hr className='divider' />
                        <p className="text">
                            With over five years of perseverance, Sai Enterprises has emerged as a leading
                            manufacturer of quality tapes and specialty packaging. It has established its presence
                            since 2018 in Indian and International Markets. Besides tapes, the company also works
                            dedicatedly towards manufacturing, developing, and marketing a wide variety of
                            packaging products and solutions. With its continuous research, strategic location,
                            integrated production line, and dedicated skilled team, Sai Enterprises continues to
                            serve some of the best corporate houses and has created its own space in the packaging
                            industry.
                        </p>

                    </div>
                    <div className='image' data-aos="zoom-out-left">
                        <img src="./images/about-1.png" alt="Sai enterprise factory" />
                    </div>
                </section>

                <div className='core-strength'>
                    <h2>Core Strength</h2>
                    <hr className='divider' />

                    <section className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12 core-strength-insider">
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md" data-aos="fade-right" >
                            <img src="./images/quality.png" alt="quality" height={100} width={100} />
                            <h2 className="text-2xl font-bold my-4">Consistent Quality</h2>
                            <p>The highest product quality is at the core of everything we do. A very high emphasis is given towards quality raw material, precise production process, and a strong quality check.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md" data-aos="fade-right" >
                            <img src="./images/process.png" alt="quality" height={100} width={100} />
                            <h2 className="text-2xl font-bold my-4">Uninterrupted Supply</h2>
                            <p>We've set up and integrated the most competent and robust manufacturing and supply chain system to ensure continuous supply even for the most challenging demands.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md" data-aos="fade-left" >
                            <img src="./images/price.png" alt="quality" height={100} width={100} />
                            <h2 className="text-2xl font-bold my-4">Competitive Price</h2>
                            <p>Competitiveness gives the winning edge. Not just competitive price, but the best value proposition is always maintained to ensure our clients have a competitive edge in the market.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md" data-aos="fade-left" >
                            <img src="./images/ecology.png" alt="quality" height={100} width={100} />
                            <h2 className="text-2xl font-bold my-4">Sustainable Approach</h2>
                            <p>Throughout our manufacturing process, from raw material procurement to production and delivery, we try to adapt the most possible green methods to ensure good sustainability.</p>
                        </div>
                    </section>
                </div >

                <section className="text-center my-6">
                    <h2 className="heading_1 text-start">Why Choose Us</h2>
                    <hr className='divider' />
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md text-center" data-aos="fade-right" >
                            <FaShieldAlt className="flip-180 h-12 w-12 text-blue-500 mx-auto mb-4" />
                            <h3 className="text-xl mb-2">Reliability</h3>
                            <p className="text-gray-700">We are known for our reliable and consistent supply chain, ensuring that you get your materials on time, every time.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md text-center" data-aos="fade-right" >
                            <FaTruck className="flip-180 h-12 w-12 text-green-500 mx-auto mb-4" />
                            <h3 className="text-xl mb-2">Fast Delivery</h3>
                            <p className="text-gray-700">Our efficient logistics ensure quick delivery, minimizing your wait time and maximizing productivity.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md text-center" data-aos="fade-left" >
                            <FaTruck className="flip-180 h-12 w-12 text-red-500 mx-auto mb-4" />
                            <h3 className="text-xl mb-2">Certified Quality</h3>
                            <p className="text-gray-700">Our products are certified for quality assurance, giving you peace of mind with every purchase.</p>
                        </div>
                        <div className="bg-white why_choose_box p-6 rounded-lg shadow-md text-center" data-aos="fade-left" >
                            <FaGlobe className="flip-180 h-12 w-12 text-yellow-500 mx-auto mb-4" />
                            <h3 className="text-xl mb-2">Global Reach</h3>
                            <p className="text-gray-700">With a global network, we are equipped to meet your needs no matter where you are located.</p>
                        </div>
                    </div>
                </section>

                <section className="visionMission" data-aos="fade-up" >
                    <div className=" why_choose_box p-6 rounded-lg shadow-md" >
                        <h2 className=''>Our Vision</h2>
                        <hr className=' divider_color_black' />
                        <ul>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Deliver innovative and sustainable packaging solutions that meet the evolving needs of our customers.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Be recognized as a global leader in the packaging industry, committed to environmental stewardship and responsible manufacturing practices.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Continuously improve our processes and products, ensuring that we set the standard for quality and sustainability in the packaging sector.</li>
                        </ul>

                        <h2>Our Mission</h2>
                        <hr className=' divider_color_black' />
                        <ul>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Innovate and manufacture a comprehensive range of high-quality packaging materials that adhere to the highest standards.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Offer our products at the most competitive rates, ensuring value for our customers and stakeholders.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Foster and nurture continuous business relationships built on trust, reliability, and mutual growth.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Integrate advanced technologies and eco-friendly practices in all our operations, promoting a sustainable future.</li>
                            <li> <FaStarOfLife className=' pr-1 star_icon' /> Empower our employees and partners through continuous learning and development, ensuring excellence at every level of our organization.</li>
                        </ul>

                    </div>
                </section>
            </div >
        </>
    );
}

export default AboutUs;
