import React from "react";
import "../css/HomeProduct.css"; // Ensure you have this CSS file for styling
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ],
};

function HomeProduct() {
    const products = [

        {
            "name": "Insulation Tapes",
            "image": "https://n4.sdlcdn.com/imgs/j/l/8/PVC-Electrical-Insulation-Tape-30-SDL917126230-1-bb8f6.jpg",
            "shortDescription": "It is flexible, and long-lasting, and is a great solution for insulating electrical connections.",
            "details": {
                "material": "PVC, RUBBER",
                "thickness": "Available Thickness From 36µ to 70µ",
                "width": "12mm to 288mm",
                "length": "Up to 4000mts",
                "available_items": "Plain, Black, Colours"
            }
        },

        {
            name: "Printing Tapes",
            "image": "https://5.imimg.com/data5/SELLER/Default/2021/3/BT/OO/KM/4393896/custom-printed-tape.jpg",

            shortDescription: "Durable strapping rolls for secure packaging.",
            link: "/products/strapping-rolls",
        },

        {
            name: "PVC Stretch ",
            "image": "../images/stretch film.png",
            shortDescription: "It's One type of Surface protetction film to cover the glass or plastic lens.",
            link: "/products/stretch-cling",
        },

        {
            name: "Masking Tapes / High temp.",
            "image": "https://offimart.com/1073-medium_default/masking-tapes-20mtrs2-inch-pack-of-3-multiuse-carpenter-labeling-painting-packing.jpg",
            shortDescription: "High-temperature masking tapes for specialized applications.",
            link: "/products/masking-tapes",
        },

        {
            name: "Surface Protection Tape.",
            image: "https://th.bing.com/th/id/OIP.MG7gLLjFHutuVA5kqvuyQQHaHa?w=750&h=750&rs=1&pid=ImgDetMain",
            shortDescription: "For the purpose of effective bonding sheet metals, plastics, and stainless steel plates",
            link: "/products/floor-marking-insulation-tapes",
        },
    ];

    return (

        <>
            <div className="home_products_container">
                <div className="products_heading">
                    <h1>Our <span className="span">Most Demand</span> Products</h1>
                    <p>
                        Check out our latest products and find something you need for your business.</p>
                </div>
                <div className="slider-container">
                    <Slider {...settings}>

                        {products.map((product, index) => (
                            <div key={index} className="slide">
                                <img src={product.image} alt={product.name} className="product-image" />
                                <div className="product-info">
                                    <h3>{product.name}</h3>
                                    <p>{product.shortDescription}</p>
                                    <Link to='/products' className="view-more-button" onClick={() => window.scrollTo(0, 0)}>
                                        View More <IoIosArrowRoundForward size={20} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>



        </>

    );
}

export default HomeProduct;
