import React, { useEffect } from 'react';
import '../css/AboutUs.css'
import { FaThumbsUp, FaChalkboardTeacher, FaBusinessTime, FaSmile } from 'react-icons/fa';
import { GoLinkExternal } from "react-icons/go";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { Link } from 'react-router-dom';

const QuickAboutUs = () => {
    useEffect(() => {
        AOS.init({
            disable: 'phone',
            duration: 1000,
            offset: 300,
            easing: 'ease-out-cubic',
        });
    }, []);
    return (
        <div className="pattern QuickAboutUs ">
            <div className="quick-about-us" data-aos="fade-up-right"    >
                <div className='quick_about_header'>
                    <h1 className='heading_h1'>Who We Are <span className='span'>Sai Enterprise</span>
                    </h1>

                </div>

                <div className="company-info">
                    {/* <img src='./images/company.svg' alt=" Logo" className="company-logo" /> */}
                    <div className="company-details">
                        <div>

                            <p>
                                With over five years of dedicated perseverance, Sai Enterprises has established itself as a leader in manufacturing top-quality tapes and specialty packaging solutions. Our journey, which began in 2018, has seen us grow and expand in both Indian and international markets. We are committed to delivering innovative, sustainable, and high-quality packaging materials at the most competitive rates.

                            </p>
                        </div>


                        <div className="features flex flex-wrap justify-center p-4 ">
                            <div className="feature-box cursor-pointer">
                                <FaThumbsUp size={50} className="icon" />
                                <h3>Quality Services</h3>
                                <p>Providing top-notch services with a focus on quality and reliability.</p>
                            </div>
                            <div className="feature-box cursor-pointer">
                                <FaChalkboardTeacher size={50} className="icon" />
                                <h3>Training & Awareness</h3>
                                <p>Empowering our team and clients through continuous learning and development.</p>
                            </div>
                            <div className="feature-box cursor-pointer">
                                <FaBusinessTime size={50} className="icon" />
                                <h3>Business Expertise</h3>
                                <p>Leveraging extensive industry knowledge to provide the best solutions.</p>
                            </div>
                            <div className="feature-box cursor-pointer">
                                <FaSmile size={50} className="icon" />
                                <h3>Customer Satisfaction</h3>
                                <p>Ensuring a high level of customer satisfaction with our products and services.</p>
                            </div>
                        </div>
                        <div className=' my-4'>
                            <Link to="/aboutus" onClick={() => window.scrollTo(0, 0)}>
                                <Button className='more_btn' variant="outlined" elevation={2} endIcon={<GoLinkExternal />}>
                                    More
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default QuickAboutUs;
