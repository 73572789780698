import React, { useEffect } from 'react';
import '../css/Contact.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { FaInstagram, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { BasicBreadcrumbs } from '../components/Breadcrumbs';

const Contact = () => {
    useEffect(() => {
        AOS.init({
            disable: 'phone',
            duration: 1000,
            offset: 300,
            easing: 'ease-out-cubic',
        });
    }, []);

    return (
        <div className='outer_contact_wrapper'>
            <BasicBreadcrumbs props="Contact Us" />
            <div className="contact_container">
                <div className="contact_form_wrapper">
                    <div className="lg:w-1/2 p-2 lg:p-12">
                        <h1 className="text-4xl font-bold text-blue-800 mb-8">Contact Us</h1>
                        <hr className='divider' />
                        <div className="space-y-6 contact_info">
                            <div className="flex items-center space-x-4">
                                <FaPhoneAlt className="contact_icon text-blue-600 w-8 h-8" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-800">Call Us</h2>
                                    <p className="text-gray-600">+91 97 65886984</p>

                                    <p className="text-gray-600">+91 94 21088345</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <FaEnvelope className="contact_icon text-blue-600 w-8 h-8" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-800">Mail ID</h2>
                                    <p className="text-gray-600">sales@saienterprisegroup.com</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <FaMapMarkerAlt className=" text-blue-600 w-12 h-12" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-800">Reach Us</h2>
                                    <p className="text-gray-600">
                                        <strong>Office and Factory:</strong> Plot No- 112, Pawar Estate, Mule Vasti, Waki Road, Birdavadi, Chakan Pune - 410501.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <a href="#" className="socialContainer containerOne">
                                        <FaInstagram className="socialSvg instagramSvg" />
                                    </a>
                                    <a href="#" className="socialContainer containerTwo">
                                        <FaTwitter className="socialSvg twitterSvg" />
                                    </a>
                                    <a href="#" className="socialContainer containerThree">
                                        <FaLinkedin className="socialSvg linkdinSvg" />
                                    </a>
                                    <a href="#" className="socialContainer containerFour">
                                        <FaWhatsapp className="socialSvg whatsappSvg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2">
                        <div className='form_box'>
                            <form className="form" data-aos="fade-up" data-aos-duration="2000" action="https://formspree.io/f/mzzprnwe" method="POST">
                                <div className="flex-column">
                                    <label>Name</label>
                                </div>
                                <div className="inputForm">
                                    <input type="text" name='name' className="input" placeholder="Enter your Name" />
                                </div>
                                <div className="flex-column">
                                    <label>Email</label>
                                </div>
                                <div className="inputForm">
                                    <input type="email" name='email' className="input" placeholder="Enter your Email" />
                                </div>
                                <div className="flex-column">
                                    <label>Phone</label>
                                </div>
                                <div className="inputForm">
                                    <input type="number" name='number' className="input" placeholder="Enter your Phone Number" />
                                </div>
                                <div className="flex-column">
                                    <label>Message</label>
                                </div>
                                <div className="inputForm">
                                    <textarea className="input textarea" name='message' placeholder="Enter your Message"></textarea>
                                </div>
                                <button type="submit" className="button-submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
