import React from 'react';
import '../css/Footer.css'
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <div className="footer_wrapper">
                <div className="head">
                    <div>
                        <div className="footer-logos" >
                            <img className="logo-1" src="./images/logo-4.png" alt="" />

                            <img className='logo-2' src="./images/iso-certified.png" alt="" />
                        </div>
                        <Divider className=' mt-2' />

                        <p>Sai Enterprises- Your Trusted Packaging Partner</p>
                    </div>
                </div>
                <div className="links">
                    <div className='company aboutCompany'>
                        <h3>Sai Enterprise</h3>
                        <span>Sai Enterprises, an ISO-certified leader in tapes and specialty packaging solutions,
                            has been delivering excellence since 2018. With a strong presence in Indian and international markets, we are dedicated to providing innovative, sustainable, and top-quality packaging materials. Experience our commitment to quality at the most competitive prices.</span>
                    </div>
                    <div className="aboutCompany">
                        <h4>About Company</h4>

                        <Link to="/aboutus" onClick={scrollToTop} >  <p>Who We Are</p></Link>
                        <Link to="/contactus" onClick={scrollToTop} >  <p>Contact Us</p></Link>
                        <Link to="/products" onClick={scrollToTop} >  <p>Products</p></Link>
                        <Link to="/certifications" onClick={scrollToTop} >  <p>Certifications</p></Link>




                    </div>
                    <div className="aboutCompany">
                        <h4>Products</h4>

                        <Link to="/products" onClick={scrollToTop} >  <p>Tapes</p></Link>
                        <Link to="/products" onClick={scrollToTop} >  <p>Foams</p></Link>
                        <Link to="/products" onClick={scrollToTop} >  <p>Bags</p></Link>
                        <Link to="/products" onClick={scrollToTop} >  <p>Rools</p></Link>
                        <Link to="/products" onClick={scrollToTop} >  <p>Board</p></Link>


                    </div>

                    <div className="aboutCompany">
                        <h4>Legals</h4>
                        <Link to="/privacy-policy" onClick={scrollToTop}>  <p>Privacy Policy</p></Link>
                        <Link to="/report" onClick={scrollToTop}>  <p>Report</p></Link>
                        <Link to="/privacy-policy" onClick={scrollToTop}>     <p >Terms and Condition</p></Link>
                    </div>
                    <div className="social">
                        <h4>Social Links</h4>
                        <div className="social-icons">
                            <i className="fa fas-brands fa-linkedin"></i>
                            <i className="fa fas-brands fa-instagram"></i>
                            <i className="fa fas-brands fa-twitter"></i>
                            <i className="fa fas-brands fa-youtube"></i>
                            <i className="fab fa-facebook-f"></i>
                        </div>
                        <div className='download_app'>
                            <div>
                                <p>sales@saienterprisegroup.com</p>
                                <p>Office & Factory
                                    <br />
                                    Plot No- 112,Pawar Estate ,Mule Vasti , Waki Road , Birdavadi, Chakan Pune - 410501.
                                </p>

                            </div>
                            <div>
                                <p>+91 976 588 6984</p>
                            </div>

                        </div>
                    </div>
                </div>
                <hr />
                <div className='footer_last'><p className=' text-center'> All rights reserved Sai Enterprise | 2020-2024

                </p></div>
            </div>
        </>
    );
};

export default Footer;
