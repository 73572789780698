import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import AOS from 'aos';
import 'aos/dist/aos.css';
const faqs = [
    {
        question: "1. What types of packaging materials do you offer?",
        answer: "We offer a wide range of packaging materials, including BOPP tapes, specialty tapes, bubble bags, foam bags, strapping rolls, plastic bags, and more. Our products are designed to meet diverse industrial and consumer packaging needs."
    },
    {
        question: "2. Are your products environmentally friendly?",
        answer: "Yes, we are committed to sustainability and offer a range of eco-friendly packaging solutions. Our products are designed with recyclable materials and sustainable practices in mind to reduce environmental impact."
    },
    {
        question: "3. Do you provide custom packaging solutions?",
        answer: "Absolutely! We specialize in providing custom packaging solutions tailored to meet specific requirements. Whether it's custom printing on tapes or specialized packaging for unique products, we can create the perfect solution for your needs."
    },
    {
        question: "4. How can I place an order?",
        answer: "You can place an order by contacting our sales team via email or phone. We also offer an online ordering system for your convenience. Please visit our Contact Us page for more information."
    }
    // Add more FAQs as needed
];


export default function FAQAccordion() {
    useEffect(() => {
        AOS.init({
            disable: 'phone',
            duration: 1000,
            offset: 300,
            easing: 'ease-out-cubic',
        });
    }, []);
    return (
        <div className="faq-container">
            <h2 className="faq-heading text-start flex">Frequently Asked Questions</h2>
            <hr className='divider' />
            <div className='content' data-aos="fade-up">
                {faqs.map((faq, index) => (
                    <Accordion key={index} className="faq-item">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography className="faq-question font-bold">{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="faq-answer">{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
}
