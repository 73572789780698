import React from 'react'
import '../css/Blogs.css'
const Blogs = () => {
    return (
        <div className='blogs'>
            <i><h1>Launching Soon</h1></i>
        </div>
    )
}

export default Blogs
