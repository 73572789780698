import React from 'react'
import '../css/OurPartners.css'
const OurPartners = () => {
    const row1 = [
        "../images/partner-1.png",
        "https://logowik.com/content/uploads/images/mahindra-suv1293.jpg",
        "https://companieslogo.com/img/orig/LUMAXIND.NS_BIG-80633668.png?t=1720244492",
        "../images/partner-3.png",
        "https://wteinfra.com/asest/images/logo1-1.png",
    ];

    const row2 = [
        "https://download.logo.wine/logo/R%C3%B6chling_Group/R%C3%B6chling_Group-Logo.wine.png",
        "https://essemautoelectricals.com/Images/logo-essem.png",
        "https://www.camlinfs.com/contact-us/wp-content/uploads/2024/02/CFS_logo_with_tag_line8-e1721199140727.png",
        "../images/ravi-dyeware.png",
        "https://cvforum.in/wp-content/uploads/2019/02/unideritend.png",
        "https://www.vasantgroup.com/wp-content/uploads/2021/04/logo.jpeg",
    ];

    return (
        <div className="AppContainer">
            <div className="Wrapper">
                <h1 className="Text heading_h1">Our Great <span className='span'>Partners</span></h1>
                <div className="text-md lg:text-lg text-gray-600">Trusted collaborations that drive success: Our esteemed partners in delivering top-notch enterprise solutions.</div>


                <div className="Marquee">
                    <div className="MarqueeGroup">
                        {row1.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                    <div className="MarqueeGroup">
                        {row1.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="Marquee">
                    <div className="MarqueeGroup2">
                        {row2.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                    <div className="MarqueeGroup2">
                        {row2.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurPartners
