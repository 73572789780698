import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export function BasicBreadcrumbs({ props }) {
    return (
        <div className='breadcrumbs ' role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>

                <Typography color="text.primary">{props}</Typography>
            </Breadcrumbs>
        </div>
    );
}
export function DetailBreadcrumbs({ props }) {
    return (
        <div className='breadcrumbs ml-36 pt-10' role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" to="/products">
                    {props.first}
                </Link>

                <Typography color="text.primary">{props.second}</Typography>
            </Breadcrumbs>
        </div>
    );
}
