import React from 'react'
import { GoMail } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
const TopHeader = () => {
    return (
        <div>
            <div className="upper_header">
                <div className="social-icons">
                    <i className="fa fas-brands fa-linkedin"></i>
                    <i className="fa fas-brands fa-instagram"></i>
                    <i className="fa fas-brands fa-twitter"></i>
                    <i className="fa fas-brands fa-youtube"></i>
                    <i className="fab fa-facebook-f"></i>
                </div>

                <div className='contact'>
                    <Link
                        to='mailto:sales@saienterprisegroup.com'>
                        <div>
                            <GoMail />
                            <p className=' mr-3'>sales@saienterprisegroup.com</p>
                        </div>
                    </Link>
                    <div>
                        <IoCallOutline />
                        <p>+91 976 588 6984</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader
