import React from 'react';

const Report = () => {
    return (
        <div className="p-8 bg-gray-100 text-gray-800">
            <h1 className="text-3xl font-semibold mb-4">Report</h1>
            <section className="mb-6">
                <h2 className="text-2xl font-medium mb-2">Incident Reporting</h2>
                <p>
                    At Sai Enterprises, we value the safety and satisfaction of our customers. If you encounter any issues with our products or services, we encourage you to report them immediately to our customer service team. This includes, but is not limited to, product defects, delivery discrepancies, or any other concerns.
                </p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-medium mb-2">Feedback Mechanism</h2>
                <p>
                    Your feedback is essential for us to improve our offerings. We have established a dedicated channel for reporting incidents, providing suggestions, or submitting complaints. Each report is carefully reviewed, and appropriate action is taken to address the concerns raised.
                </p>
            </section>
            <section>
                <h2 className="text-2xl font-medium mb-2">Contact Information for Reporting</h2>
                <p>
                    To report any issues, please contact our customer service at:
                    <br />
                    Phone: +91 9765886984, +91 7743886984
                    <br />
                    <br />

                    Email: <a href="mailto:contact@saienterprisegroup.com" className="text-blue-600">sales@saienterprisegroup.com</a>
                    <br />
                    Email: <a href="mailto:dadapatil@saienterprisegroup.com" className="text-blue-600">dadapatil@saienterprisegroup.com</a>
                </p>
                <br />
                <p>
                    Please include all relevant details, such as order numbers, product information, and a description of the issue, to expedite the resolution process.
                </p>
            </section>
        </div>
    );
};

export default Report;
