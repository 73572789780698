import React, { useState } from 'react';
import { FaUser, FaBuilding, FaEnvelope, FaPhone, FaProductHunt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BuyNow = () => {
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        email: '',
        mobileNumber: '',
        product: '',
    });

    const products = [
        { name: "Self Adhesive BOPP Tapes / Printed" },
        { name: "Bubble Bag / Foam bag" },
        { name: "Strapping Rolls (PP/PET)" },
        { name: "Plastic Bags & Rolls (HM/LD/PP)" },
        { name: "Stretch / Cling" },
        { name: "Angle Board" },
        { name: "Masking Tapes / High temp." },
        { name: "Cross Filament / HDPE/ Duct" },
        { name: "Floor Marking / Insulation Tapes" },
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success('Form submitted! Our sales team will contact you soon.');

        setFormData({
            name: '',
            companyName: '',
            email: '',
            mobileNumber: '',
            product: '',
        });
    };
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">Fill the Form to Buy</h2>
                <p className="text-gray-600 text-center mb-6">Our sales team will guide and assist you. Please fill out the form below, and we will contact you soon.</p>

                <form action='https://formbold.com/s/3V7y0' method='POST' className="space-y-4">
                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaUser className="text-gray-500 mr-2" />
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        />
                    </div>
                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaBuilding className="text-gray-500 mr-2" />
                        <input
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        />
                    </div>

                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaEnvelope className="text-gray-500 mr-2" />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        />
                    </div>

                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaPhone className="text-gray-500 mr-2" />
                        <input
                            type="tel"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={formData.mobileNumber}
                            onChange={handleChange}
                            required
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        />
                    </div>

                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaProductHunt className="text-gray-500 mr-2" />
                        <select
                            name="product"
                            id="product"
                            required
                            value={formData.product}
                            onChange={handleChange}
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        >
                            <option value="" disabled>Select a product</option>
                            {products.map((product, index) => (
                                <option key={index} value={product.name}>{product.name}</option>
                            ))}
                        </select>
                    </div>

                    <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline">Submit</button>
                </form>
                <ToastContainer position="top-center" />
            </div>
        </div>
    );
};

export default BuyNow;
