import React from 'react';

const Privacy = () => {
    return (
        <div className="p-8 bg-gray-100 text-gray-800">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Data Collection and Use</h2>
                <p>
                    Sai Enterprises is committed to protecting the privacy of our customers and stakeholders. We collect personal data only when it is voluntarily provided by you, such as through inquiries, orders, or registrations. This data may include your name, contact information, and other relevant details necessary for the provision of our services. We use this information exclusively to fulfill your requests, process orders, and improve our services.
                </p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
                <p>
                    We implement appropriate technical and organizational measures to protect the personal data we collect against unauthorized access, alteration, disclosure, or destruction. Your data is securely stored and handled with the utmost care to ensure its confidentiality.
                </p>
            </section>
            <section>
                <h2 className="text-2xl font-semibold mb-2">Data Sharing</h2>
                <p>
                    Sai Enterprises does not sell, trade, or otherwise transfer your personal information to third parties, except as required by law or as necessary to fulfill your requests (e.g., shipping companies). We may share your information with trusted partners who assist us in operating our business, so long as those parties agree to keep your information confidential.
                </p>
            </section>
        </div>
    );
};

export default Privacy;
