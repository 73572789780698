import Avatar from '@mui/material/Avatar';

import Card from '@mui/material/Card';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const testimonials = [
    {
        name: "Jane Doe",
        position: "CEO, Acme Inc.",
        image: "/images/user1.jpg",
        feedback: "The enterprise software from this company has been a game-changer for our business. It's intuitive, powerful, and has helped us streamline our operations in ways we never thought possible.",
    },
    {
        name: "John Doe",
        position: "CTO, Globex Inc.",
        image: "/images/user2.jpg",
        // initials: "JD",
        feedback: "I was hesitant to switch to this enterprise software at first, but the onboarding process was seamless, and the support team has been incredibly helpful. It's now an integral part of our daily operations.",
    },
    {
        name: "Emily Miller",
        position: "CFO, Stark Industries",
        image: "/images/user3.png",
        // initials: "EM",
        feedback: "The reporting and analytics features in this enterprise software have been invaluable for our finance team. We can now make data-driven decisions with confidence, which has had a direct impact on our bottom line.",
    },
];

function TestimonialCard({ name, position, image, initials, feedback }) {

    useEffect(() => {
        AOS.init({
            disable: 'phone',
            duration: 1000,
            offset: 300,
            easing: 'ease-out-cubic',
        });
    }, []);
    return (
        <Card className="flex flex-col gap-4 p-6">
            <div className="flex items-center gap-4">
                <Avatar className="w-12 h-12 border">
                    <img src={image} alt={name} />

                </Avatar>
                <div>
                    <h4 className="font-semibold  text-start">{name}</h4>
                    <p className="text-sm">{position}</p>
                </div>
            </div>
            <blockquote className="text-md font-medium leading-relaxed feedback_text text-start">
                {feedback}
            </blockquote>
        </Card>
    );
}

export default function Testimonials() {
    return (
        <section className="w-full py-12 md:py-24 lg:py-32 testimonials flex justify-center" data-aos="fade-up">
            <div className="container grid gap-8 px-4 md:px-6">
                <div className="flex flex-col items-center space-y-4 text-center">
                    <div className="space-y-2 header">
                        <h2 className="">
                            What Our <span className="span">Customers</span> Say
                        </h2>
                        <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                            Hear from the people who rely on our enterprise software to power their businesses.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3" data-aos="zoom-in-up">
                    {testimonials.map((testimonial, index) => (

                        <>
                            <TestimonialCard key={index} {...testimonial} />


                        </>

                    ))}
                </div>
            </div>
        </section>
    );
}
