import React, { useEffect } from 'react'
import Home from './pages/Home'
import './App.css'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Contact from './pages/Contact'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import TopHeader from './components/TopHeader'
import Footer from './components/Footer'
import AboutUs from './pages/AboutUs'
import Products from './pages/Products'
import Blogs from './pages/Blogs'
import BuyNow from './components/BuyNow'
import ProductDetails from './pages/ProductDetails'
import { ToastContainer, toast } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Privacy from './pages/Privacy'
import Report from './pages/Report'
import ResponsiveAppBar from './components/Navbar'

const App = () => {

  useEffect(() => {
    AOS.init({
      disable: 'phone',
      duration: 1000,
      offset: 300,
      easing: 'ease-out-cubic',
    });
    AOS.refresh(); // Refresh AOS after initialization to handle any dynamically loaded content
  }, []);

  return (
    <BrowserRouter>

      <TopHeader />
      <ToastContainer position="top-center" />

      <ResponsiveAppBar />

      <Routes>
        <Route path='/' element={<Home />} />

        <Route path='/contact' exact element={<Contact />} />
        <Route path='/products' exact element={<Products />} />
        <Route path='/blogs' exact element={<Blogs />} />
        <Route path='/buy-now' exact element={<BuyNow />} />
        <Route path='/aboutus' exact element={<AboutUs />} />
        <Route path='/products/:productName' element={<ProductDetails />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/report' element={<Report />} />
      </Routes>
      <Footer />
      <FloatingWhatsApp
        phoneNumber={9765886984}
        accountName={'Sai Enterprise'}
        avatar='../images/fav-icon-2.png'
        styles={{
          // Custom styles for the component
          zIndex: 1000,
          border: '1px solid red',
        }}
        chatboxStyles={{
          border: '1px solid red',
          // Custom styles for the chatbox
          color: '#000', // Change this to the desired text color
        }}
      />
    </BrowserRouter>

  )
}

export default App
