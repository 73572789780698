import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import './HomePage.css';
import QuickAboutUs from '../components/QuickAbout';
import HomeProduct from '../components/HomeProduct';
import NewsFeed from '../components/NewsFeed';
import Testimonials from '../components/Testimonials';
import FAQAccordion from '../components/FAQs';
import OurPartners from '../components/OurPartners';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import CountUp, { useCountUp } from 'react-countup';
import { SlCalender } from "react-icons/sl";
import Divider from '@mui/material/Divider';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import SimpleSlider from '../components/HeroSection';
import BasicExample from '../components/HeroSection';
import UncontrolledExample from '../components/HeroSection';
import { Tooltip } from '@mui/material';

import { FloatingWhatsApp } from 'react-floating-whatsapp'

const scrollToTop = () => {
    window.scrollTo(0, 0);
};
const Home = () => {

    useEffect(() => {
        AOS.init({
            disable: 'phone',
            duration: 1000,
            offset: 300,
            easing: 'ease-out-cubic',
        });
    }, []);

    return (
        <>
            <HeroSection />
            {/* <HomeProduct /> */}
            {/* <UncontrolledExample /> */}
            <QuickAboutUs />
            <OurPartners />
            <CounterUp />
            <ScheduleDemo />

            <Testimonials />
            <HomeProduct />
            <DownloadBrochure />
            {/* <NewsFeed /> */}
            <FAQAccordion />



        </>
    );
};
export default Home;

const DownloadBrochure = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '../docs/Sai Enterprises Brochure.pdf';
        link.download = 'brochure.pdf';

        link.click();
        setTimeout(() => {
            toast.success('File Downloaded!');
        }, 2000);

    };



    return (
        <div className="download-brochure" >
            <div className='content' data-aos="fade-up">
                <h2>Download Our <span className='span'>Brochure</span></h2>
                <p className='text-lg text-gray-600'>Get all the details about our products and services in one convenient package.</p>
                <Tooltip title="Download brochure " placement="bottom">
                    <Button className='download-btn' variant="contained" startIcon={<DownloadIcon />} onClick={handleDownload}>
                        Download
                    </Button>
                </Tooltip>
            </div>
            <div className='image' data-aos="zoom-in">
                <img src="./images/brochure.png" alt="sai enterprise brochure" />
            </div>
        </div>
    );
}



const metricsData = [
    { label: 'Total Experience (Years)', value: 5, icon: './images/expertise.png' },
    { label: 'Happy Clients', value: 100, icon: './images/client.png' },
    { label: 'Number of Products', value: 20, icon: './images/product.png' },
    { label: 'Projects Completed', value: 20, icon: './images/project.png' },
];

const CounterUp = () => {

    useCountUp({
        ref: 'counter',
        end: 1234567,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
    });
    return (

        <div className='counter_comp'>
            <div className="text-center mb-8">
                <h1 className=" text-gray-800 mb-4">Our <span className='span'>Achievements</span></h1>
                <p className="text-md lg:text-lg text-gray-600">
                    We are proud of the work we've done and the clients we've served. Here are some
                    of the highlights of our journey so far.
                </p>
            </div>
            <div className="flex flex-wrap justify-center p-4 ">
                {metricsData.map((metric, index) => (
                    <div
                        key={index}
                        className="w-full sm:w-1/2 lg:w-1/4 p-4"
                    >
                        <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 cursor-pointer counter_box" data-aos="fade-left">
                            <div className="flex flex-col items-center">
                                <img src={metric.icon} alt="asdnasd" height={50} width={50} />
                                <h3 className="text-lg font-semibold text-gray-700 my-2 text-center">{metric.label}</h3>
                                <div className="text-4xl counter_value text-gray-600">
                                    <CountUp end={metric.value} duration={2.5} enableScrollSpy suffix='+' />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};


const ScheduleDemo = () => {
    return (
        <>
            <div className="schedule_demo_wrapper">
                <div data-aos="fade-right">
                    <h2>Want to grow your <span className='span'>business</span> with our solution?</h2>
                    <p className='text-md lg:text-lg text-gray-600'>Schedule a personalized demo to see how our platform can help your business.</p>
                </div>
                <Divider orientation="vertical" flexItem data-aos="zoom-in" />

                <div className='demo_btn' data-aos="fade-left">
                    <Link to='/contact' onClick={scrollToTop}> <Button variant="outlined" startIcon={<SlCalender />}>
                        Schedule a Demo
                    </Button></Link>
                </div>
            </div>

        </>
    )
}