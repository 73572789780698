import React from 'react';
import { useParams } from 'react-router-dom';
import '../css/ProductDetails.css';
import Contact from './Contact';
import productsData from '../data/product.json'
import { DetailBreadcrumbs } from '../components/Breadcrumbs';

const ProductDetails = () => {

    const { productName } = useParams();
    const product = productsData.find(p => p.name.replace(/\s+/g, '-').toLowerCase() === productName);

    if (!product) {
        return <div>Product not found</div>;
    }
    return (
        <>

            <DetailBreadcrumbs props={{ first: 'Products', second: 'Product Details' }} />
            <div className="product-details-container">
                <div className="contact-form-section">
                    <h1>Contact Us for More Details</h1>
                    {/* <hr className='divider' /> */}
                    <div className='form_box'>
                        <form className="form" data-aos="fade-up" data-aos-duration="2000" action="https://formbold.com/s/91p5R"
                            method="POST">
                            <div className="flex-column">
                                <label>Name</label>
                            </div>
                            <div className="inputForm">
                                <input type="text" name='name' className="input" placeholder="Enter your Name" />
                            </div>

                            <div className="flex-column">
                                <label>Email</label>
                            </div>
                            <div className="inputForm">
                                <input type="email" name='email' className="input" placeholder="Enter your Email" />
                            </div>
                            <div className="flex-column">
                                <label>Phone</label>
                            </div>
                            <div className="inputForm">
                                <input type="number" name='number' className="input" placeholder="Enter your Email" />
                            </div>

                            <div className="flex-column">
                                <label>Message</label>
                            </div>
                            <div className="inputForm">
                                <textarea className="input textarea" name='message' placeholder="Enter your Message" ></textarea>
                            </div>

                            <button type="submit" className="button-submit">Submit</button>
                        </form>
                    </div>

                </div>
                <div className="product-details-section">


                    <>
                        <h1>{product.name}</h1>
                        <img src={product.image} alt={product.name} />
                        <p className='shortDesc'>{product.description}</p>
                        {/* <h1>Dimensions</h1>
                        <hr className='divider' />
                        <div className='extra-details'>
                            <li><img src="../images/check.png" width={30} height={30} alt="check box" />Material: {product.details.material}</li>
                            <li><img src="../images/check.png" width={30} height={30} alt="check box" />Thickness: {product.details.thickness}</li>
                            <li><img src="../images/check.png" width={30} height={30} alt="check box" />Available Items: {product.details.available_items}</li>
                            <li><img src="../images/check.png" width={30} height={30} alt="check box" />Length: {product.details.length}</li>
                            <li><img src="../images/check.png" width={30} height={30} alt="check box" />Widht: {product.details.width}</li>

                        </div> */}
                    </>




                    <div>
                        <ul>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
